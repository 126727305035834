@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Cherolina;
  src: url(./fonts/Cherolina.ttf);
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #607274;
  color: white;
}

.logo {
  font-family: "Cherolina", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.tender { 
  color: #F0997D;
}

#visual-1 {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -2;
  box-shadow: 3px;
}

#visual-2 {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: -1;
  opacity: 50%;
  box-shadow: 3px;
}

#visual-3 {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 50%;
  box-shadow: 3px;
}


.animator {
  animation: fade-in ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}
